import React from "react"
import {Footer} from 'components/Footer';

import * as styles from './styles.module.scss'

export default function Home() {
    return (
      <>
        <div className={ styles.NotFound_Conatiner }>
          <h3 className={ styles.NotFound_Title }>404 Not Found</h3>
        </div>
        
        <Footer/>
      </>
    )
}